<template>
  <component
    :is="tag"
    v-model:visible="visible"
    :title="title"
    :over-header="isMobileOrTablet ? true : null"
    :centered="isDesktop ? true : null"
    :size="isDesktop ? size : null"
    :scrollable="isDesktop ? scrollable : null"
    :no-close-on-backdrop="isDesktop ? noCloseOnBackdrop : null"
    :no-close-on-esc="isDesktop ? noCloseOnEsc : undefined"
    :hide-footer="hideFooter"
    :hide-header-close="hideHeaderClose"
    @close="hidden"
    @hide="hide"
    @hidden="hidden"
    @shown="shown"
  >
    <template #header>
      <slot name="header" />
    </template>

    <slot />

    <template #footer>
      <slot name="footer" />
    </template>
  </component>
</template>

<script setup lang="ts">
import type { ModalDrawerSize, ModalHideTrigger, OverlayDrawerHideTrigger } from '~/types/style-guide'
import { OverlayDrawer, ZModal } from '#components'

withDefaults(defineProps<{
  title?: string
  size?: ModalDrawerSize
  scrollable?: boolean
  noCloseOnBackdrop?: boolean
  noCloseOnEsc?: boolean
  modalClass?: string
  hideFooter?: boolean
  hideHeaderClose?: boolean
}>(), {
  size: 'md',
})

const emit = defineEmits<{
  hidden: []
  hide: [{ trigger: ModalHideTrigger | OverlayDrawerHideTrigger }]
  shown: []
}>()

const visible = defineModel<boolean>('visible', { required: true })

const { isMobileOrTablet, isDesktop } = useDevice()
const tag = computed(() => isMobileOrTablet ? OverlayDrawer : ZModal)

function hide({ trigger }: { trigger: ModalHideTrigger }) {
  emit('hide', { trigger })
}

function hidden() {
  emit('hidden')
}

function shown() {
  emit('shown')
}
</script>
